<template>
  <div class="SwissTrust">
    <heard-pc v-show="Isshow" @scrollTop="scrollTop"></heard-pc>
    <heard-pc-background v-show="!Isshow"></heard-pc-background>
    <div class="banner">
      <img width="100%" src="../assets/banner4.png" alt="" />
      <div class="title">
        <div class="h1">SWISS TRUST</div>
      </div>
      <!-- <div class="min_background"></div> -->
    </div>
    <!-- <div class="before"></div> -->
    <h1 id="CoreAdvantages">Advantages</h1>
    <div class="navpc">
      <div class="navtitlepc">
        <div
          v-for="item in CoreAdvantages"
          :key="item.index"
          @click="handleper(item.index)"
          :class="{ active: item.index === index }"
        >
          {{ item.title }}
        </div>
      </div>
      <!-- <div class="jiantou" @click="handleper">
        <svg
          class="icon"
          aria-hidden="true"
          style="font-size: 35px; color: #fff"
        >
          <use xlink:href="#icon-shangyibu"></use>
        </svg>
      </div> -->
      <div class="CoreAdvantages">
        <div class="left">
          <img width="100%" :src="CoreAdvantages[index].img" alt="" />
          <!-- <img src="../assets/CoreAdvantages1.png" alt="" />
          <img src="../assets/CoreAdvantages2.png" alt="" />
          <img src="../assets/CoreAdvantages3.png" alt="" />
          <img src="../assets/CoreAdvantages4.png" alt="" />
          <img src="../assets/CoreAdvantages5.png" alt="" /> -->
        </div>
        <div class="right">
          <h2>{{ CoreAdvantages[index].title }}</h2>
          <p>{{ CoreAdvantages[index].text }}</p>
        </div>
      </div>
      <!-- <div class="jiantou" style="margin-right: 20px" @click="handlenext">
        <svg
          class="icon"
          aria-hidden="true"
          style="font-size: 35px; color: #fff"
        >
          <use xlink:href="#icon-xiayibu"></use>
        </svg>
      </div> -->
    </div>
    <div class="navmin">
      <div class="navtitlemin">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="item in CoreAdvantages" :key="item.index">
            <img :src="item.img" />
            <div class="navminTitle">
              {{ item.title }}
            </div>
            <div class="text">{{ item.text }}</div>
          </van-swipe-item>
        </van-swipe>
        <!-- <div v-for="item in CoreAdvantages" :key="item.index">
          <div class="navminTitle">
            {{ item.title }}
          </div>
          <img :src="item.img" alt="" />
          <div class="text">{{ item.text }}</div>
        </div> -->
      </div>
    </div>
    <h1 id="FeaturesofSwissTrust" style="margin-top: 82px">
      Features of Swiss Trust
    </h1>
    <div class="content">
      <div class="m_bottom"></div>
      <div class="article">
        <div class="contents">
          <img src="../assets/SwissTrust1.png" alt="" />
          <h2>
            Swiss trust has a long history and is managed by an army of
            professionals
          </h2>
          <p>Swiss competitive talent ranks No.1 in the world.</p>
        </div>
        <div class="contents">
          <img src="../assets/SwissTrust2.png" alt="" />
          <h2>
            Swiss franc is one of the best safe haven currencies without foreign
            exchange control allowing free flow of fund
          </h2>
          <p>
            Compared to the dollar or the euro, Swiss Franc is widely regarded
            as the most preferred safe haven currency for a long time.
          </p>
        </div>
        <div class="contents">
          <img src="../assets/SwissTrust3.png" alt="" />
          <h2>Switzerland adopts "The Hague Trust Convention"</h2>
          <p>
            Swiss trust and Hong Kong trust can serve you at the same time. As
            Switzerland adopts "The Hague Trust Convention", the Swiss trust
            enjoys double security of simultaneous application of both Swiss and
            Hong Kong trust laws.
          </p>
        </div>
        <div class="contents">
          <img src="../assets/SwissTrust4.png" alt="" />
          <h2>
            Swiss legal system is one of the favored by high-net-worth customers
            all over the world
          </h2>
          <p>
            ◆
            <span
              >The federal constitution is the foundation of the Swiss legal
              system which secures the autonomy of each canton in the
              country.</span
            >
          </p>
          <p>
            ◆<span>
              The simultaneous application of statutory law and case law is a
              major characteristic of the Swiss legal system.</span
            >
          </p>
          <p>◆ Democracy.</p>
          <p>
            ◆<span>
              A world-renowned place for arbitration and dispute
              settlement.</span
            >
          </p>
        </div>
        <div class="contents">
          <img src="../assets/SwissTrust5.png" alt="" />
          <h2>
            The country is politically, economically and socially stable which
            is favorable for safe custody of wealth of clients
          </h2>
          <p>◆ Best reputation and integrity.</p>
          <p>
            ◆
            <span
              >In 1648, Switzerland declared independence and its neutral policy
              against war with any nation.</span
            >
          </p>
          <p>
            ◆
            <span
              >Its political stability, democracy and sophisticated financial
              infrastructure are well known to the world.</span
            >
          </p>
          <p>
            ◆
            <span
              >Swiss banks are the custodians of 40% of all personal assets in
              the world.</span
            >
          </p>
        </div>
        <div class="contents">
          <img src="../assets/SwissTrust6.png" alt="" />
          <h2>
            Switzerland is the most favored country of the high-net-worth around
            the world
          </h2>
          <p>
            ◆<span>
              The minimum threshold for opening a Swiss trust account is USD 5
              million.
            </span>
          </p>
          <p>
            ◆
            <span
              >Only the high-net-worth are eligible for Swiss trust
              service.</span
            >
          </p>
          <p>
            ◆<span>
              A world-class global financial manager (i.e. Swiss private
              banking).</span
            >
          </p>
        </div>
        <div class="contents">
          <div class="child">
            <div class="left">
              <img src="../assets/SwissTrust7.png" alt="" />
            </div>
            <div class="right">
              <h2>
                Swiss has one of the world's most sophisticated financial
                supervisory system
              </h2>
              <h3>◆ The supervisory body - FINMA</h3>
              <p>
                FINMA is an independent organization and is responsible to Swiss
                Parliament directly. It is charged with the duty to supervise
                the operation of banks, insurance companies, stock exchange, any
                collective investment plan and its management firm and fund
                management house, distributors and insurance arbitration
                institute in Switzerland. It ensures the effective operation of
                the financial market by protecting the interest of creditors,
                investors and insurance policy owners.
              </p>
              <h3 style="margin-top: 30px">◆ The supervisory body - ARIF</h3>
              <p>
                Established on 15 March 1999 and headquartered in Geneva, ARIF
                is a non-profit taking public organization. It is charged with
                the duty to fight money laundering and terrorism based on the
                federal law. It became an accredited self-regulatory supervisory
                body by FINMA in 2009 for independent asset management firm.
                There are currently more than 490 members in the organization.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <img width="100%" src="../assets/FeaturesofSwissTrust.png" alt="" />
      </div> -->
    </div>
  </div>
</template>
<script>
/* import HeardPc from "../components/HeardPc.vue";
import HeardPcBackground from "../components/HeardPcBackground.vue"; */
export default {
  name: "Home",
  components: {
    /* HeardPc,
    HeardPcBackground, */
  },
  data() {
    return {
      index: 0,
      scrollTop: 0,
      Isshow: true,
      CoreAdvantages: [
        {
          index: 0,
          img: "https://www.hongkongtrust.com.hk/vue/img/CoreAdvantages1.png",
          title: "High Degree of Compatibility",
          text: "Switzerland adopts the “Hague Trust Convention”, thereby making the simultaneous utilization of Swiss trust and Hong Kong trust possible.",
        },
        {
          index: 1,
          img: "https://www.hongkongtrust.com.hk/vue/img/CoreAdvantages2.png",
          title: "Economic System",
          text: "A free-market economy.",
        },
        {
          index: 2,
          img: "https://www.hongkongtrust.com.hk/vue/img/CoreAdvantages3.png",
          title: "Currency",
          text: "Swiss franc. The currency itself offers an attractive long term investment option aside from gold as it offers protection from inflation, security from risk and potential for returns (quoted from Investopedia).",
        },
        {
          index: 3,
          img: "https://www.hongkongtrust.com.hk/vue/img/CoreAdvantages4.png",
          title: "Competitiveness of Switzerland",
          text: "Ranked #1 (IMD World Competitiveness Ranking 2021).",
        },
        {
          index: 4,
          img: "https://www.hongkongtrust.com.hk/vue/img/CoreAdvantages5.png",
          title: "Political Stability",
          text: "Switzerland is known for its political stability. The country has, for more than 500 years (since 1505), never been in any conflict or war with any other country.",
        },
      ],
    };
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop === 0 ? (this.Isshow = true) : (this.Isshow = false);
      /* console.log(this.scrollTop); */
    },
    handleper(data) {
      this.index = data;
      /* this.index === 0 ? (this.index = 4) : this.index--; */
    },
    handlenext() {
      this.index === 4 ? (this.index = 0) : this.index++;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    document.body.style.overflowX = "hidden";
  },
};
</script>
<style lang="scss" scoped>
img {
  transition: all 0.6s;
  cursor: pointer;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
/* .before {
  width: 100vw;
  height: 994px;
  background: #000;
  opacity: 0.5;
  position: absolute;
  top: 0px;
} */
.banner {
  width: 100vw;
  /* background-image: url(../assets/banner4.png); */
  position: relative;
  .min_background {
    display: none;
    background: #fff;
    height: 17px;
    position: absolute;
    width: 84vw;
    margin-left: 8vw;
    bottom: 0;
  }
}
.title {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* z-index: 100; */
  position: absolute;
  top: 45%;
  .h1 {
    font-size: 55px;
    color: #fff;
  }
}
h1 {
  font-size: 38px;
  margin: 19px 0 40px;
  font-weight: 500;
  font-family: "Georgia";
}
.m_bottom {
  display: none;
}
.navmin {
  display: none;
}
.navpc {
  width: 60vw;
  margin: 0 auto;
  // font-family: FZZJ;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-end; */
  margin-bottom: 100px;
  .navtitlepc {
    display: flex;
    font-size: 20px;
    color: #bebebe;
    justify-content: space-evenly;
    margin-bottom: 60px;
    font-family: Georgia;
    margin-top: -30px;

    .active {
      color: #000;
    }
    div {
      width: 20%;
      text-align: center;
    }
  }
  .navtitlepc div:hover {
    color: #a95228;
  }
  .CoreAdvantages {
    width: 60vw;
    display: flex;
    text-align: left;
    justify-content: space-between;
    margin-top: -32px;

    .left {
      width: 54%;
      overflow: hidden;
    }
    .right {
      width: 40%;
      padding-top: 10px;
      h2 {
        font-size: 26px;
        font-family: "Georgia";
      }
      p {
        text-align: left;
        font-size: 20px;
        font-weight: 500;
        font-family: "contert";
      }
    }
  }
  .CoreAdvantages:hover {
    img {
      transform: scale(1.2);
    }
  }
}
.jiantou {
  width: 84px;
  height: 84px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 60vw;
  margin: -62px auto;
  .article {
    margin: 89px auto 100px;
    // font-family: FZZJ;
    text-align: left;
    font-size: 16px;
    border-top: 1px solid #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .contents:nth-child(7) {
      width: 100%;
      padding-bottom: 0px;
      .child {
        display: flex;
        background-color: #fff;
        justify-content: space-between;

        .left {
          width: 31%;
          img {
            height: 100%;
          }
        }
        .right {
          width: 65.5%;
          background-color: #f2f2f2;
          padding-bottom: 20px;
          h2 {
            width: 96%;
          }
          h3 {
            width: 96%;
            font-size: 18px;
            margin: 0 auto;
            font-weight: 500;
          }
          p {
            width: 96%;
          }
        }
      }
    }
    .contents {
      width: 31%;
      margin-top: 30px;
      background-color: #f2f2f2;
      padding-bottom: 10px;
      overflow: hidden;

      p {
        font-family: "contert";
      }
      img {
        width: 100%;
      }
    }
    .contents:hover {
      img {
        transform: scale(1.1);
      }
    }
    div {
      text-align: start;
    }
    h2 {
      font-size: 20px;
      margin: 0 auto;
      margin-bottom: 10px;
      margin-top: 5px;
      width: 90%;
    }
    h3 {
      width: 90%;
      margin: 0 auto;
      font-size: 20px;
      margin: 5px 0px;
      font-weight: 300;
      font-family: Georgia;
    }
    p {
      width: 90%;
      margin: 0 auto;
      font-weight: 300;
      margin-top: 20px;
      overflow-wrap: break-word;
      word-break: unset;
      display: flex;
    }
  }
}
span {
  display: inline-block;
  margin-left: 5px;
}
@media (min-width: 1025px) and (max-width: 1500px) {
  h1 {
    margin: 30px 0;
  }
  .navpc {
    // font-family: FZZJ;
    width: 80vw;
    margin-bottom: 50px;
    .CoreAdvantages {
      width: 80vw;
      .right {
        width: 44%;
      }
    }
  }
  .content {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title .h1 {
    font-size: 36px;
  }
  h1 {
    font-size: 24px;
    margin: 30px 0;
  }
  .navpc {
    width: 80vw;
    margin-bottom: 50px;
    // font-family: FZZJ;
    .navtitlepc {
      font-size: 16px;
    }
    .CoreAdvantages {
      width: 80vw;
      .right {
        width: 40%;
        h2 {
          font-size: 22px;
          text-align: left;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .navmin {
    display: none;
  }
  .content {
    width: 90vw;
    .article {
      margin-top: 30px;
      //  font-family: FZZJ;
      p {
        font-size: 14px;
      }
      h2 {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 767px) {
  // // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  ::v-deep .va n-swipe__indicator {
    background-color: #371010;
  }
  ::v-deep .van-swipe__indicator--active {
    background-color: #1989fa;
  }
  .title .h1 {
    font-size: 30px;
    /* top: 35%; */
    // font-family: "webfont2";
  }
  .title {
    top: 35%;
  }
  #CoreAdvantages {
    font-size: 20px;
    margin: 17px auto 20px;
    color: #5d5d5d;
  }
  h1 {
    font-size: 24px;
    margin: 30px 0;
    margin-bottom: 10px;
  }
  .banner {
    margin-top: 64px;
    .min_background {
      display: block;
    }
  }
  .navpc {
    display: none;
    // font-family: FZZJ;
  }
  .navmin {
    display: block;
    width: 80vw;
    margin: 0px auto;
    .navtitlemin {
      img {
        width: 100%;
      }
      .text {
        /* border-bottom: 1px solid; */
        padding-bottom: 20px;
      }
      .navminTitle {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin: 16px 0 10px;
        color: #5f5f5f;
      }
      .text {
        /* width: 60vw; */
        margin: 0 auto 10px;
        text-align: left;
        color: #5f5f5f;
        font-family: "contert";
      }
      div {
        text-align: justify;
        font-size: 14px;
      }
    }
  }
  #FeaturesofSwissTrust {
    background: #093f8d;
    margin: 57px auto;
    padding-top: 20px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  .m_bottom {
    width: 30%;
    margin: 0px auto;
    height: 2px;
    background: #fff;
    display: block;
  }
  .content {
    width: 88vw;
    background: #093f8d;
    padding: 11px 6vw 0 6vw;
    margin-bottom: 1px;
    .article {
      margin-top: 24px;
      border-top: 0px;
      margin-bottom: -1px;
      //  font-family: FZZJ;
      .contents:nth-child(7) .child {
        display: block;
        background: #f2f2f2;
        h3 {
          font-size: 16px !important;
          margin-bottom: 5px !important;
          margin-top: 10px !important;
          font-weight: 500;
        }
        .left {
          width: 100%;
          text-align: center;
        }
        .right {
          width: 100%;
          h2 {
            width: 90%;
          }
          h3 {
            width: 90%;
          }
          p {
            width: 90%;
            margin-top: -4px;
          }
        }
      }
      .contents {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 20px;
        text-align: center;
        background-color: #fff;
        padding-bottom: 20px;
        img {
          width: 90%;
          margin-top: 14px;
        }
      }
      p {
        text-align: left;
        font-size: 14px;
        color: #99a3b1;
        font-family: "contert";
      }
      h3 {
        font-size: 15px;
        color: #99a3b1;
      }
      h2 {
        font-size: 16px;
        text-align: left;
        color: #093f8d;
      }
    }
  }
}
</style>
